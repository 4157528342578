import { GatsbyPageProps, Token } from '@/types';
import { ArticleList } from '../../articles';
import XxxMacdChartTemplate from '@/templates/crypto/xxx-macd-chart';

export default function SolanaMacdChart({ location }: GatsbyPageProps) {
  return (
    <XxxMacdChartTemplate
      coinAbbreviation="SOL"
      coinFullName="Solana"
      token={Token.SOLUSDT}
      location={location}
      articleId={ArticleList.SOLANA_MACD_CHART}
    ></XxxMacdChartTemplate>
  );
}
